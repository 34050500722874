<template>
  <v-container style="margin-left: 60px;">
    <v-row class="justify-center" v-if="!interview">
      <v-col>
        <div class="mt-5 text-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
          <p class="body font-weight-thin">Chargement...</p>
        </div>
      </v-col>
    </v-row>
    <v-row class="justify-center" v-else-if="interview.textFile === ''">
      <v-col class="fill-height">
      <v-scroll-y-transition mode="out-in">
        <div class="text-center">
          <v-img class="mx-auto mb-5" src="../../../assets/no-data.svg" max-width="200"></v-img>
          <p class="display-1 font-weight-thin grey--text text--lighten-1">
            Aucune transcription n'a été chargée
          </p>
          <v-btn small class="mt-4" color="teal" outlined :to="url('upload/text')">
            <v-icon small>mdi-upload</v-icon> Uploader un fichier
          </v-btn>
        </div>
      </v-scroll-y-transition>
    </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
      <v-tabs grow>
        <v-tab><v-icon left>mdi-chart-line</v-icon>Statistiques</v-tab>
        <v-tab><v-icon left>mdi-magnify</v-icon>Explorer</v-tab>

        <v-tab-item>
          <SyntaxicFeatures :analysis="analysis"/>
        </v-tab-item>

        <v-tab-item>
          <Explorer :patientId="patientId" :interviewId="interviewId"/>
        </v-tab-item>
      </v-tabs>
    </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MixinInterviews from '@/mixins/interviews';
import MixinPatients from '@/mixins/patients';
import Explorer from '@/components/interviews/Explorer.vue';
import SyntaxicFeatures from '@/components/common/SyntaxicFeatures.vue';

export default {
  name: 'InterviewToolBasics',
  components: { Explorer, SyntaxicFeatures },
  mixins: [MixinPatients, MixinInterviews],
  props: {
    patientId: String,
    interviewId: String,
  },
  methods: {
    url(path) {
      return `/patient/${this.patientId}/interview/${this.interviewId}/${path}`;
    },
  },
  async mounted() {
    this.analysis = await this.getAnalysisResult(
      this.patientId,
      this.interviewId,
      ['syntax-basics', 'syntax-advanced', 'disfluences', 'deictics'],
    );
    this.interview = await this.getInterviewInfo(this.patientId, this.interviewId);
    this.analysis.name = this.interview.name;
  },
  data: () => ({
    tabs: null,
    analysis: null,
    interview: null,
  }),
};
</script>
