<template>
  <v-menu :close-on-content-click="true" :nudge-width="200" offset-x>
  <template v-slot:activator="{ on, attrs }">
    <v-btn class="ma-0" icon color="blue" v-bind="attrs" v-on="on">
      <v-icon>mdi-information-outline</v-icon>
    </v-btn>
  </template>
  <v-card>
    <v-list dense>
      <v-subheader>{{ title }}</v-subheader>
      <v-list-item two-line v-for="(item, i) in items" :key="i">
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
          <div v-if="!item.split">
            <v-list-item-subtitle>{{ item.content }}</v-list-item-subtitle>
          </div>
          <div v-else>
            <v-list-item-subtitle class="d-flex justify-space-around">
              <v-chip v-for="(option, j) in item.content.split(' ')" :key="j"
              color="red darken-3" dark small>
                {{ option }}
              </v-chip>
            </v-list-item-subtitle>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'InfoBox',
  props: {
    title: String,
    items: Array,
  },
};
</script>
