<template>
  <v-row class="flex-row align-self-stretch">
    <v-col>
      <InfoBox title="Configuration" :items="configurationItems"/> Options
      <v-row>
        <v-col cols="2">
           <div class="d-flex d-inline mt-5">
             <v-text-field class="mr-3"
               dense
               outlined
               rounded
               label="Début"
               type="number"
               :counter="dialog.length+1"
               :rules="[(v) => (v < end)]"
               v-model="start"/>
             <v-text-field
               dense
               outlined
               rounded
               label="Fin"
               type="number"
               :counter="dialog.length+1"
               :rules="[(v) => (v > start)]"
               v-model="end"/>
            </div>
            <v-slider v-model="factor" label="Schéma CONLLU"
              track-color="grey" always-dirty min="50" max="150"
              append-icon="mdi-magnify-plus" preprend-icon="mdi-magnify-minus">
             </v-slider>
           </v-col>
           <v-col>
             <v-scroll-y-transition mode="out-in">
             <v-expansion-panels style="max-width: 1000px;" class="mx-auto mt-3"
              accordion>
               <v-expansion-panel v-for="(item,i) in dialog.slice(start, end)" :key="i"
               @click="getSvg(item.conll)">
                 <v-expansion-panel-header>
                   <span class="body-2">
                     <v-btn-toggle>
                       <v-btn outlined x-small>
                       <v-icon left x-small>mdi-message</v-icon>
                       {{ i+parseInt(start, 10) }} / {{ dialog.length }} -
                       {{ item.participant }}
                       </v-btn>
                       <v-tooltip bottom>
                         <template v-slot:activator="{ on }">
                           <v-btn x-small icon
                           @click="exportSvg(`turn-${i}.svg`)" v-on="on">
                             <v-icon x-small>mdi-export</v-icon>
                           </v-btn>
                         </template>
                         <span>Exporter l'image</span>
                       </v-tooltip>
                     </v-btn-toggle>
                       {{ item.speech || '[?]' }}
                    </span>
                   </v-expansion-panel-header>
                 <v-expansion-panel-content light>
                 <div class="text-center">
                   <v-progress-linear v-show="svg.loading === true" indeterminate>
                 </v-progress-linear>
                   <img :src="svg.url"
                   :style="`transform-origin: left;
                   transform: scale(${factor/100.0});`"
                   />
                 </div>
                 </v-expansion-panel-content>
               </v-expansion-panel>
             </v-expansion-panels>
           </v-scroll-y-transition>
         </v-col>
       </v-row>
    </v-col>
  </v-row>
</template>

<script>
import MixinRequest from '@/mixins/request';
import MixinInterviews from '@/mixins/interviews';
import MixinPatients from '@/mixins/patients';
import InfoBox from '@/components/common/InfoBox.vue';

export default {
  name: 'Explorer',
  mixins: [MixinRequest, MixinInterviews, MixinPatients],
  components: { InfoBox },
  props: {
    patientId: String,
    interviewId: String,
  },

  methods: {
    exportSvg(name) {
      const downloadLink = document.createElement('a');
      downloadLink.href = this.svg.url;
      downloadLink.download = name;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    getSvg(conll) {
      this.svg.loading = true;
      setTimeout(async () => {
        // Remove old URL
        URL.revokeObjectURL(this.svg.url);
        const svgData = await this.getSVGFile(this.patientId, this.interviewId, { conll });
        const preface = '<?xml version="1.0" standalone="no"?>\r\n';
        const svgBlob = new Blob([preface, svgData], { type: 'image/svg+xml;charset=utf-8' });
        this.svg.url = URL.createObjectURL(svgBlob);
      }, 0);
      this.svg.loading = false;
    },
  },

  async mounted() {
    const data = await this.getAnalysisResult(
      this.patientId,
      this.interviewId,
      ['speech', 'conll'],
    );
    this.dialog = data.speech;
  },

  data: () => ({
    svg: { source: '', url: '', loading: false },
    factor: 100,
    start: 0,
    end: 20,
    dialog: [],
    configurationItems: [
      { title: 'UDPipe', content: 'Version 1.2.0' },
      { title: 'Modèle', content: 'French-spoken 2.5' },
      { title: 'Options', content: 'tag parse input=horizontal output=conllu', split: true },
    ],
  }),
};
</script>
