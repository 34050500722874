<template>
  <div>
    <v-row>
      <v-col v-for="(item,i) in analysis.analysis" :key="i">
        <SingleAnalysis :analysis="item"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SingleAnalysis from './SingleAnalysis.vue';

export default {
  name: 'SyntaxicFeatures',
  components: { SingleAnalysis },
  props: {
    analysis: Object,
  },
};
</script>
